import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {InfoCircle} from "react-bootstrap-icons";
import React from "react";

interface IMessageModalProps {
    show: boolean,
    onHide: () => void,
    message: string,
}

export const MessageModal = (props: IMessageModalProps) => {
    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Body>
                <div className={"d-flex w-100 align-items-center justify-content-center mb-5"}>
                    <InfoCircle className={"mr-3"} size={40}/>
                    <h1>Informations</h1>
                </div>
                <h4 className={"mb-4 mb-5 text-center"}>
                    {props.message}
                </h4>
                <Button variant="info" className="my-2 border-0 w-100" onClick={props.onHide}>
                    OK
                </Button>
            </Modal.Body>
        </Modal>
    )
}