import Modal from "react-bootstrap/Modal";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ArrowDownCircle, ArrowUpCircle, X} from "react-bootstrap-icons";
import React from "react";
import {ICategory, ICategoryItem} from "./EditPanel";

interface ICategoryItemsModalProps {
    show: boolean,
    onHide: () => void,
    category: ICategory,
    handleRemoveCategoryElement: (index: number) => void,
    handleItemNameEdit: (index: number, value: string) => void,
    handleItemPriceEdit: (index: number, value: number) => void,
    handleSwipeUpItem: (index: number) => void,
    handleSwipeDownItem: (index: number) => void,
    addElement: () => void,
}

export const CategoryItemsModal = (props: ICategoryItemsModalProps) => {

    const renderItem = (item: ICategoryItem, index: number) => (
        <Col className={"col-12 mb-4"} key={index}>
            <div className={"d-flex w-100 align-items-center"}>
                <div className={"d-flex"}>
                    <X className={"text-danger cursor-pointer"} size={"40px"}
                       onClick={() => props.handleRemoveCategoryElement(index)}/>
                </div>
                <div className={"d-flex w-100"}>
                    <Form.Control
                        type="text"
                        onChange={e => props.handleItemNameEdit(index, e.target.value)}
                        value={item.name}
                        placeholder={"Nom de l'élément"}
                    />
                </div>
                <div className={"d-flex ml-2"}>
                    <Form.Control
                        type="number"
                        min="0"
                        max="99"
                        step="0.50"
                        onChange={e => props.handleItemPriceEdit(index, Number(e.target.value))}
                        placeholder="0"
                        value={item.price > 0 ? item.price : ""}
                    />
                </div>
                <div className={"d-flex flex-column h-100 align-items-center ml-2"}>
                    {index > 0 ?
                        <ArrowUpCircle
                            size={"25px"}
                            onClick={() => props.handleSwipeUpItem(index)}
                            className={"arrow mb-1"}
                        />
                        : null
                    }
                    {index < props.category.items.length - 1 ?
                        <ArrowDownCircle
                            size={"25px"}
                            onClick={() => props.handleSwipeDownItem(index)}
                            className={"arrow mt-1"}
                        />
                        : null
                    }
                </div>
            </div>
        </Col>
    )

    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Body>
                <Row>
                    <Col className={"text-right"}>
                        <X size={"60px"} className={"cursor-pointer"} onClick={props.onHide}/>
                    </Col>
                </Row>
                <h4 className={"mb-4 ml-2"}>{props.category.name}</h4>
                <Row>
                    {props.category.items.map(renderItem)}
                </Row>
                <Button variant="dark" className="my-2 border-0 w-100" onClick={props.addElement}>
                    Ajouter un élément
                </Button>
                <Button variant="success" className="my-2 border-0 w-100" onClick={props.onHide}>
                    Valider
                </Button>
            </Modal.Body>
        </Modal>
    )
}