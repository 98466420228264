import Modal from "react-bootstrap/Modal";
import {Col, Row} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import React from "react";

interface ISubCategoriesModalProps {
    onHide: () => void,
    show: boolean,
    items: any
}

export const SubCategoriesModal = (props: ISubCategoriesModalProps) => {
    return (
        <Modal {...props} show={props.show} className={"noprint"} centered>
            <Row>
                <Col className={"text-right"}>
                    <X size={"60px"} className={"cursor-pointer m-3"} onClick={props.onHide}/>
                </Col>
            </Row>
            <Modal.Body className={"pt-0"}>
                {Object.keys(props.items).map((category) => {
                    return <Row key={category} className={"striped mb-4"}>
                        <Col className={"col-12 mb-1 text-center wisdom-font"}>
                            <h2>{category}</h2>
                        </Col>
                        {Object.keys(props.items[category]).map((item) => {
                            return <Col key={item} className={"col-12 mb-1 px-4"}>
                                <div className={"d-flex align-items-end item"}>
                                    <h5>• {item}</h5>
                                    <h5 className={"ml-auto"}>{props.items[category][item]}€</h5>
                                </div>
                            </Col>
                        })}
                    </Row>
                })}
            </Modal.Body>
        </Modal>
    );
}