import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import socket from "../context/SocketIOInstance";
import {Check2} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {SubCategoriesModal} from "./SubCategoriesModal";
import {ICategory} from "./EditPanel";

interface IHomeState {
    categoriesMap: Array<ICategory>,
    boissons: { [item: string]: { [item: string]: number } },
    douceurs: { [item: string]: { [item: string]: number } },
    loaded: boolean,
    modalItems: object,
    showModal: boolean,
    actualDate: string
}

const initialState: IHomeState = {
    categoriesMap: new Array<ICategory>(),
    boissons: {},
    douceurs: {},
    loaded: false,
    modalItems: {},
    showModal: false,
    actualDate: ""
}

export const Home = () => {
    const [state, setState] = useState<IHomeState>(initialState);

    useEffect(function onMount() {
        socket.emit('retrieve_data');
        socket.on('data', (data) => {
            let dateObject = !!data?.date ? new Date(data.date) : new Date();
            const options: any = {weekday: 'long', month: 'long', day: 'numeric'};
            let formatedDate = dateObject.toLocaleDateString('fr-FR', options);
            formatedDate = formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
            setState(prevState => ({
                ...prevState,
                categoriesMap: data?.items ?? [],
                actualDate: formatedDate,
                loaded: true
            }));
        });
        socket.emit('retrieve_boissons');
        socket.on('boissons', (boissons) => {
            setState(prevState => ({...prevState, boissons: boissons}));
        });
        socket.emit('retrieve_douceurs');
        socket.on('douceurs', (douceurs) => {
            setState(prevState => ({...prevState, douceurs: douceurs}));
        });
    }, [])

    const showModal = (items: { [item: string]: { [item: string]: number } }) => () => {
        setState(prevState => ({...prevState, showModal: true, modalItems: items}));
    }

    const closeModal = () => setState(prevState => ({...prevState, showModal: false}));

    if (!state.loaded) return null;
    return (
        <Container fluid>
            <Row className="mt-3">
                <Col className={"col-12 mb-2 d-print-none"}>
                    <Row className={"align-items-center text-center"}>
                        <Col className={"pr-1"}>
                            <Row>
                                <Col className={"col-12 mb-3"}>
                                    <a href="tel:+33 6 71 72 89 78">
                                        <img className="social-logo" src="phone-logo.svg" alt="phone"/>
                                    </a>
                                </Col>
                                <Col className={"col-12"}>
                                    <a href="https://www.tripadvisor.fr/Restaurant_Review-g187253-d6779500-Reviews-La_Buvette_du_Lac-Marseille_Bouches_du_Rhone_Provence_Alpes_Cote_d_Azur.html">
                                        <img className="social-logo" src="tripadvisor-logo.jpg" alt="tripadvisor"/>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={"col-6 p-0"}>
                            <img className="logo" src="logo.PNG" alt="logo"/>
                        </Col>
                        <Col className={"pl-1"}>
                            <Row>
                                <Col className={"col-12 mb-3"}>
                                    <a href="https://www.facebook.com/La-Buvette-du-Lac-102540868137013/">
                                        <img className="social-logo" src="logo-fb.png" alt="facebook"/>
                                    </a>
                                </Col>
                                <Col className={"col-12"}>
                                    <a href="https://www.instagram.com/buvettedulac/">
                                        <img className="social-logo" src="insta-logo.jpg" alt="instagram"/>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className={"col-12 text-center text-white mb-1"}>
                    <Row className="d-none d-print-flex align-items-center">
                        <Col className={"col-5"}>
                            <div className={"d-flex"}>
                                <div className={"d-flex flex-column align-items-center justify-content-center mr-5"}>
                                    <img width={80} src="/t-shirt.png" alt="tee-shirt"/>
                                    <h5 style={{whiteSpace: "nowrap"}}>T-shirt à 25€</h5>
                                </div>
                                <div className={"d-flex flex-column align-items-center justify-content-center"}>
                                    <img width={80} src="/phone-black.svg" alt="phone"/>
                                    <h5 style={{whiteSpace: "nowrap"}}>06 71 72 89 78</h5>
                                </div>
                            </div>
                        </Col>
                        <Col className={"col-2 d-flex justify-content-center"}>
                            <img width={150} src="logo-black.PNG" alt="logo"/>
                        </Col>
                        <Col className={"col-5"}>
                            <div className={"d-flex justify-content-end"}>
                                <div className={"mr-5 d-flex flex-column align-items-center justify-content-center"}>
                                    <img width={60} src="/card.svg" alt="no credit card"/>
                                    <h5 style={{width: "100px"}}>Pas de CB</h5>
                                </div>
                                <div className={"d-flex text-left flex-column"}>
                                    <h5><Check2 className="text-success" size={30}/>Chèque</h5>
                                    <h5><Check2 className="text-success" size={30}/>Ticket Resto</h5>
                                    <h5><Check2 className="text-success" size={30}/>Espèce</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <h1 className={"wisdom-font"}>{state.actualDate}</h1>
                </Col>
                <Col className={"col-12 px-4 mt-2 mb-4"}>
                    <hr/>
                </Col>
                {state.categoriesMap.map((category) => {
                    return (
                        <Col key={category.name} className={"col-12 text-white mb-4 px-4 striped d-print-block"}>
                            <Row>
                                <Col className={"col-12 wisdom-font"}>
                                    <h2>{category.name}</h2>
                                </Col>
                                {category.items.map((item, index) => {
                                    return (
                                        <Col key={index} className={"col-12 mb-1 px-4"}>
                                            <div className={"d-flex align-items-end item"}>
                                                <h5 className={"text-white"}>• {item.name}</h5>
                                                <h5 className={"text-white ml-auto"}>{item.price + "€"}</h5>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    );
                })}
                <Col className={"col-12 px-4 d-print-none"}>
                    <Row>
                        <Col className={"col-12 mb-4"}>
                            <Button className={"wisdom-font categorybtn"} variant={"light"}
                                    onClick={showModal(state.boissons)}>
                                Liste des boissons
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col className={"col-12 px-4 d-print-none"}>
                    <Row>
                        <Col className={"col-12 mb-4"}>
                            <Button className={"wisdom-font categorybtn"} variant={"light"}
                                    onClick={showModal(state.douceurs)}>
                                Douceurs d'apres-midi
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col className={"col-12 px-4 mt-2 mb-4 d-print-none"}>
                    <hr/>
                </Col>
                <Col className={"col-12 mb-2 text-white text-center wisdom-font d-print-none"}>
                    <h1>Paiement par</h1>
                </Col>
                <Col className={"col-12 px-4 text-white mb-2 d-print-none"}>
                    <h5><Check2 className={"text-success h2 m-0"}/> Chèque</h5>
                    <h5><Check2 className={"text-success h2 m-0"}/> Ticket restaurant</h5>
                    <h5><Check2 className={"text-success h2 m-0"}/> Espèce</h5>
                </Col>
                <Col className={"col-12 px-4 mt-2 mb-4 d-print-none"}>
                    <hr/>
                </Col>
                <Col className={"col-12 mb-4 text-white text-center wisdom-font d-print-none"}>
                    <a className={"text-white"} rel="noopener noreferrer" target="_blank"
                       href="https://www.google.com/maps/place/La+Buvette+Du+Lac,+20+Impasse+de+la+Planche,+13008+Marseille/@43.2612823,5.3819885,16z/data=!4m2!3m1!1s0x12c9c7429e473151:0x8decfbda97bb7f8d">
                        <div className={"d-flex flex-wrap justify-content-center"}>
                            <h1 className={"mr-3"}>Adresse</h1>
                            <img className={"social-logo"} src="gmaps-logo.png" alt="gmaps"/>
                        </div>
                    </a>
                </Col>
                <Col className={"col-12 px-4 mt-2 mb-4 d-print-none"}>
                    <hr/>
                </Col>
                <Col className={"col-12 mb-2 text-white text-center wisdom-font d-print-none"}>
                    <h1>Contact</h1>
                </Col>
                <Col className={"col-12 mb-4 text-white d-print-none"}>
                    <a href="tel:+33 6 71 72 89 78" className={"text-white"}>
                        <div className={"d-flex align-items-center"}>
                            <img width={40} src="/phone-white.svg" alt="phone"/>
                            <h5 style={{whiteSpace: "nowrap"}}>06 71 72 89 78</h5>
                        </div>
                    </a>
                </Col>
            </Row>
            <SubCategoriesModal show={state.showModal} items={state.modalItems} onHide={closeModal}/>
        </Container>
    )
}