import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Button, Col, Form, Row} from "react-bootstrap";
import {X} from "react-bootstrap-icons";

interface IPostModalProps {
    data: Array<any>,
    date: string,
    show: boolean,
    onHide: () => void,
}

interface IPostModalState {
    categories: Array<any>,
    selected: Array<any>,
    date: string,
}

const initialState: IPostModalState = {
    categories: [],
    selected: [],
    date: "",
}

export const PostModal = (props: IPostModalProps) => {
    const [state, setState] = useState<IPostModalState>(initialState);

    useEffect(function onMount() {
        generateDefaultSelected();
    }, [])

    useEffect(function onPropsChange() {
        setState(prevState => ({...prevState, categories: props.data, date: props.date}));
        generateDefaultSelected();
    }, [props])

    const handleCheck = (index: number) => {
        let selectedCopy = [...state.selected];
        selectedCopy[index] = !selectedCopy[index];
        setState(prevState => ({...prevState, selected: selectedCopy}));
    }

    const generatePost = () => {
        let dateObject = new Date(state.date);
        const options: any = {weekday: 'long', month: 'long', day: 'numeric'};
        let formatedDate = dateObject.toLocaleDateString('fr-FR', options);
        formatedDate = formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
        let generatedText = "Menu - " + formatedDate + "\n\n";
        state.categories.forEach((category, index) => {
            if (state.selected[index]) {
                generatedText += category.name + "\n";
                category.items.forEach((item: any) => {
                    generatedText += "• " + (item.name.slice(-1) === " " ? item.name.slice(0, -1) : item.name) + " " + item.price + "€\n";
                });
                generatedText += "\n";
            }
        });
        generatedText = generatedText.slice(0, -1);
        navigator.clipboard.writeText(generatedText).then(() => window.location.assign("https://www.facebook.com/La-Buvette-du-Lac-102540868137013"));
    }

    const generateDefaultSelected = () => {
        let defaultSelected: Array<any> = [];
        props.data.forEach(() => defaultSelected.push(false));
        setState(prevState => ({...prevState, selected: defaultSelected}));
    }

    return (
        <Modal show={props.show} onHide={props.onHide} size="sm" centered>
            <Row>
                <Col className={"text-right"}>
                    <X size={"60px"} className={"cursor-pointer"} onClick={props.onHide}/>
                </Col>
            </Row>
            <Modal.Body className={"pt-0"}>
                <h4 className={"mb-3 text-center"}>Sélection des catégories</h4>
                {state.categories.map((category, index) => {
                    return (
                        <div className={"d-flex flex-wrap mx-3 mb-3 w-100"} key={index}>
                            <Form.Check type="checkbox" label={category.name}
                                        checked={state.selected[index]}
                                        onChange={() => handleCheck(index)}/>
                        </div>
                    );
                })}
                <Button className={"text-center mt-3 w-100"} onClick={() => generatePost()}>
                    Partager sur facebook
                </Button>
            </Modal.Body>
        </Modal>
    );
}