import React, {useContext, useState} from "react";
import {Redirect} from "react-router-dom";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import socket from "../../context/SocketIOInstance";
import {AuthContext, IAuthContext} from "../../context/AuthContext";

export const Login = () => {
    const context = useContext<IAuthContext>(AuthContext);
    const [isError, setIsError] = useState<boolean>(false);

    const postLogin = () => {
        //Envoi des infos au serveur pour valider l'authentification
        socket.emit('login', context.password);
        //Récupération des informations de connexion
        socket.on('login_info', (success) => {
            //Si c'est un succès
            if (success)
                //Authentification validée
                context.setAuthenticated(true);
            else {
                //Authentification invalide
                context.setAuthenticated(false);
                //Erreur
                setIsError(true);
            }
        });
    }

    const submitLoginEnter = (event: any) => {
        //Si la touche est entrée, appel de la fonction d'authentification
        if (event.which === 13) postLogin();
    }

    //Si l'utilisateur est authentifié, redirection vers la page d'accueil
    if (context.authenticated) return <Redirect to="/admin"/>;

    return context.loaded ? (
        <Container className="flex-center position-ref h-100" fluid>
            <Row>
                <Col className={"col-12 mb-2"}>
                    <h3 className={"text-white text-left"}>Administrateur</h3>
                </Col>
                <Col className={"col-12"}>
                    <Form>
                        <Form.Control
                            type="password"
                            onChange={e => context.setPassword(e.target.value)}
                            onKeyPress={e => submitLoginEnter(e)}
                            placeholder="Mot de passe"
                        />
                        <Button variant="dark" className="mt-4 border-0" onClick={postLogin}>
                            Valider
                        </Button>
                    </Form>
                </Col>
                {isError ?
                    <Col className={"col-12 text-center mt-3"}>
                        <h5 className={"text-danger"}>Mot de passe incorrect</h5>
                    </Col>
                    :
                    null
                }
            </Row>
        </Container>
    ) : null;
}